<template>
  <div
    class="z-errorBar right-0 left-0 bg-error text-white small-base-text p-16 flex justify-center items-center"
    :class="{
      absolute: staticErrorBar,
      fixed: !staticErrorBar,
       'top-80': !usingUsps,
       'top-122': usingUsps,
    }"
  >
    {{ text }}
    <div
      class="absolute right-16 p-8 cursor-pointer select-none"
      @click="$emit('closeSnackBar')"
    >
      <fa :icon="['fal', 'times']"></fa>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    text: {
      type: String,
      required: true,
    },
    isError: {
      type: Boolean,
      required: false,
      default: false,
    },
    staticErrorBar: {
      type: Boolean,
      required: false,
      default: false,
    },
    usingUsps: {
      type: Boolean,
      required: false,
      default: false
    }
  },
});
</script>
